const categories = [
  {
    id: 1,
    url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR4-VqtkxPpDFYNstc8vuhYKm-RIWYm20CTblDPTBFDFIX7wJdOaMNr-7OKndzH7PhIIzs&usqp=CAU",
    categoryName: "PERI PERI CHIKEN",
  },
  {
    id: 2,
    url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRH1MxjLV-OFdbnwBmC_qiqZTMEmb4KQrujtQ&s",
    categoryName: "VALUE SNACKERS",
  },
  {
    id: 3,
    url: "https://i.ytimg.com/vi/2C5MziZDu38/hq720.jpg?sqp=-oaymwEhCK4FEIIDSFryq4qpAxMIARUAAAAAGAElAADIQj0AgKJD&rs=AOn4CLA4QNnnSohJDEMfMuHJ8xDs3krOzw",
    categoryName: "CHIKEN ROLLS",
  },
  {
    id: 4,
    url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQiCR8onF7CpL-elbCPQH-xbv6hDpj9Tx3E9g&s",
    categoryName: "CHIKEN BUCKETS",
  },
  {
    id: 5,
    url: "https://assets.telegraphindia.com/telegraph/2023/Apr/1681300921_eat.jpg",
    categoryName: "BIRYANI BUCKERS",
  },
  {
    id: 6,
    url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR3i0HA-qY-aK7WtYCRyYur1RZVn9RgU2ZzAREPA8S99_pdiNdVb9VHZ74Yyd-awbOOCWw&usqp=CAU",
    categoryName: "BOX MEALS",
  },
  {
    id: 7,
    url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQtgSpamk06rDYRNNMRFOdJk_w2DWf3IsS4wA&s",
    categoryName: "BURGERS",
  },
  {
    id: 8,
    url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS7xxtjsn_FR_wDnxDOyhotO4h4cvuS4YDK3g&s",
    categoryName: "View All Menu →",
  },
];

export default categories;

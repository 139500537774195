import React, { useState } from "react";
import "./Style.css";
import linesLogo from "../../assets/mobileLogo.png";
import { Button, Heading, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton } from "@chakra-ui/react";

const initialUserData = {
  name: "",
  email: "",
  phoneNumber: "",
  deliveryAddress: "",
};

const Checkout = ({ purchase }) => {
  const initialPaymentDetails = {
    CardHolderName: "",
    CardNumber: "",
    CVV: "",
    ExpDate: "",
    payment_amt: purchase.totalAmount,
  };

  const [userData, setUserData] = useState(initialUserData);
  const [paymentDetails, setPaymentDetails] = useState(initialPaymentDetails);
  const [payType, setPayType] = useState("");
  const [isOrderSubmitted, setIsOrderSubmitted] = useState(false); // New state to manage modal visibility

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = {
      name: userData.name,
      email: userData.email,
      phone: userData.phoneNumber,
      address: userData.deliveryAddress,
      paymentDetails: payType === "Card" ? paymentDetails : "Cash Payment",
    };

    try {
      const response = await fetch("https://formspree.io/f/mjkbdekd", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setIsOrderSubmitted(true); // Show "Thank you" modal on successful submission
      } else {
        alert("Failed to submit form");
      }
    } catch (error) {
      console.error("Submission error:", error);
      alert("An error occurred while submitting the form");
    }
  };

  // Handle expiry date input change
  const handleExpDateChange = (e) => {
    let value = e.target.value.replace(/\D/g, ""); // Remove all non-digit characters

    if (value.length >= 2) {
      value = value.slice(0, 2) + " / " + value.slice(2);
    }

    if (value.length > 7) {
      value = value.slice(0, 7); // Ensure max length for MM / YY format
    }

    setPaymentDetails({ ...paymentDetails, ExpDate: value });
  };

  const gst = parseFloat(purchase.subTotal * 0.05).toFixed(2);

  return (
    <div className="checkout-Body">
      <div className="checkout-headerSection">
        LET'S ORDER FOR DELIVERY, PICK UP, OR DINE-IN
        <button className="checkout-headerSection-button">Start Now</button>
      </div>
      <div className="checkout-parentContianer">
        <div className="checkout-cardsSection2">
          <div className="checkout-headingSection2">
            <img src={linesLogo} alt="logo" className="checkout-3lingLogo" />
            CHECKOUT
          </div>
          <div className="checkout-storedData">
            <div className="checkout-storedData-left">
              <div className="checkout-card-child">
                <form onSubmit={handleSubmit} className="checkout-form">
                  <Heading>Customer Details</Heading>
                  <input
                    type="text"
                    placeholder="Full Name*"
                    value={userData.name}
                    onChange={(e) =>
                      setUserData({ ...userData, name: e.target.value })
                    }
                    className="checkout-form-input"
                    required
                  />
                  <input
                    type="number"
                    placeholder="Phone Number*"
                    value={userData.phoneNumber}
                    onChange={(e) =>
                      setUserData({ ...userData, phoneNumber: e.target.value })
                    }
                    className="checkout-form-input"
                    required
                  />
                  <input
                    type="email"
                    placeholder="Email*"
                    value={userData.email}
                    onChange={(e) =>
                      setUserData({ ...userData, email: e.target.value })
                    }
                    className="checkout-form-input"
                    required
                  />
                  <textarea
                    placeholder="Delivery Address*"
                    value={userData.deliveryAddress}
                    onChange={(e) =>
                      setUserData({
                        ...userData,
                        deliveryAddress: e.target.value,
                      })
                    }
                    className="checkout-form-input"
                    required
                  />

                  {/* Payment Options */}
                  <Heading>Select Payment Method</Heading>
                  <div className="checkout-payment-options">
                    <label>
                      <input
                        type="radio"
                        name="paymentMethod"
                        value="Card"
                        checked={payType === "Card"}
                        onChange={() => setPayType("Card")}
                      />
                      &nbsp;Credit Card
                    </label>
                    &nbsp;&nbsp;
                  </div>

                  {payType === "Card" && (
                    <div className="checkout-payment-gateway-card">
                      <Heading>Card Details</Heading>
                      <input
                        type="text"
                        placeholder="Name on Card*"
                        value={paymentDetails.CardHolderName}
                        onChange={(e) =>
                          setPaymentDetails({
                            ...paymentDetails,
                            CardHolderName: e.target.value,
                          })
                        }
                        className="checkout-form-input"
                        required
                      />
                      <input
                        type="number"
                        placeholder="Card Number*"
                        value={paymentDetails.CardNumber}
                        onChange={(e) =>
                          setPaymentDetails({
                            ...paymentDetails,
                            CardNumber: e.target.value,
                          })
                        }
                        className="checkout-form-input"
                        required
                      />
                      <div className="checkout-payment-gateway-card-c1">
                        <input
                          type="text"
                          placeholder="MM / YY*"
                          value={paymentDetails.ExpDate}
                          maxLength={7}
                          onChange={handleExpDateChange}
                          className="checkout-form-input-c1"
                          required
                        />
                        <input
                          type="password"
                          placeholder="CVV*"
                          maxLength={3}
                          value={paymentDetails.CVV}
                          onChange={(e) =>
                            setPaymentDetails({
                              ...paymentDetails,
                              CVV: e.target.value,
                            })
                          }
                          className="checkout-form-input-c1"
                          required
                        />
                      </div>
                    </div>
                  )}
                  <br></br>
                  <Button
                    colorScheme="blue"
                    type="submit"
                    disabled={!payType}
                    pl={10}
                    pr={10}
                    pt={4}
                    pb={4}
                    borderRadius={30}
                  >
                   
                    Submit
                  </Button>
                </form>
              </div>
            </div>
            <div className="checkout-storedData-right">
              <div className="checkout-amoutBox">
                <Heading>
                  {purchase.quantity}{" "}
                  {purchase.quantity > 1 ? " ITEMS" : " ITEM"}
                </Heading>
                <div className="checkout-amountBox-total">
                  <p>Subtotal</p>
                  <p>AED {purchase.subTotal}</p>
                </div>
                <div className="checkout-amountBox-gst">
                  <p>GST</p>
                  <p>AED {gst}</p>
                </div>
                <div className="checkout-amountBox-line"></div>
                <p>Total: AED {purchase.totalAmount}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal for "Thank you" message */}
      <Modal isOpen={isOrderSubmitted} onClose={() => setIsOrderSubmitted(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Thank You for Your Order!</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <p>Your order has been placed successfully.</p>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default Checkout;
